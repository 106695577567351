import { createMuiTheme } from '@material-ui/core/styles';
import { green, grey, red, deepOrange, teal, cyan } from '@material-ui/core/colors';

const rawTheme = createMuiTheme({
  palette: {
    primary: {
      xlight: teal[50],
      light: teal[200],
      main: teal[600],
      dark: teal[900],
      background: cyan[900],
      backup: '#254e58',

    },
    secondary: {
      xlight: deepOrange[50],
      light: deepOrange['A100'],
      main: deepOrange['A400'],
      dark: deepOrange['A700'],
    },
    grey: {
      light: grey[300],
      main: grey[600],
      dark: grey[800],
    },
    warning: {
      main: '#ffc071',
      dark: '#ffb25e',
    },

    error: {
      xLight: red[50],
      main: red[500],
      dark: red[700],
    },
    success: {
      xLight: green[50],
      main: green[500],
      dark: green[700],
    },
  },
  typography: {
    fontFamily: "'Work Sans', sans-serif",
    fontSize: 14,
    fontWeightLight: 300, // Work Sans
    fontWeightRegular: 400, // Work Sans
    fontWeightMedium: 700, // Roboto Condensed
    fontFamilySecondary: "'Roboto Condensed', sans-serif",
    fontDisplay: 'swap',
  },
});

const fontHeader = {
  color: rawTheme.palette.text.primary,
  fontWeight: rawTheme.typography.fontWeightMedium,
  fontFamily: rawTheme.typography.fontFamilySecondary,
  textTransform: 'uppercase',
};

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    background: {
      ...rawTheme.palette.background,
      default: rawTheme.palette.common.white,
      placeholder: grey[200],
    },
  },
  typography: {
    ...rawTheme.typography,
    fontHeader,
    h1: {
      ...rawTheme.typography.h1,
      ...fontHeader,
      letterSpacing: 0,
      fontSize: 42,
    },
    h2: {
      ...rawTheme.typography.h2,
      ...fontHeader,
      fontSize: 36,
    },
    h3: {
      ...rawTheme.typography.h3,
      ...fontHeader,
      fontSize: 30,
    },
    h4: {
      ...rawTheme.typography.h4,
      ...fontHeader,
      fontSize: 24,
    },
    h5: {
      ...rawTheme.typography.h5,
      fontSize: 20,
      fontWeight: rawTheme.typography.fontWeightLight,
    },
    h6: {
      ...rawTheme.typography.h6,
      ...fontHeader,
      fontSize: 16,
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1,
      fontSize: 14,
    },
    body1: {
      ...rawTheme.typography.body2,
      fontWeight: rawTheme.typography.fontWeightRegular,
      fontSize: 14,
    },
    body2: {
      ...rawTheme.typography.body1,
      fontSize: 12,
    },
  },
};

export default theme;
